<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>拼单列表</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row class="row">
          <a-col :span="21">
            <a-row>
              <a-col :span="8">
                <a-form-item label="订单编号">
                  <a-input v-decorator="['orderSn']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="下单时间">
                  <a-range-picker
                    v-decorator="['time']"
                    @change="onChangeTime"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="支付状态">
                  <a-select
                    v-decorator="['orderStatus', { initialValue: '全部' }]"
                    :options="statusList"
                  ></a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="收货人地区">
                  <a-input v-decorator="['consigneeAddress']" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  label="商品装运总体积"
                  style="margin-bottom: 0px;height: 64px"
                >
                  <span class="span-input">
                    <a-input-number
                      v-decorator="[
                        'totalVolumeMin',
                        { rules: [{ validator: compareToSecondVolume }] }
                      ]"
                      :min="0"
                      class="input"
                      placeholder="商品总体积"
                    ></a-input-number>
                    <span class="separator">~</span>
                    <a-input-number
                      v-decorator="[
                        'totalVolumeMax',
                        { rules: [{ validator: compareToFirstVolume }] }
                      ]"
                      :min="0"
                      class="input"
                      placeholder="商品总体积"
                    ></a-input-number>
                  </span>
                  <span v-if="visible" style="color: red">
                    {{ visibleText }}
                  </span>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item
                  label="商品总重量"
                  style="margin-bottom: 0px;height: 64px"
                >
                  <span class="span-input">
                    <a-input-number
                      v-decorator="[
                        'totalWeightMin',
                        { rules: [{ validator: compareToSecondWeight }] }
                      ]"
                      :min="0"
                      class="input"
                      placeholder="商品总重量"
                    ></a-input-number>
                    <span class="separator">~</span>
                    <a-input-number
                      v-decorator="[
                        'totalWeightMax',
                        { rules: [{ validator: compareToFirstWeight }] }
                      ]"
                      :min="0"
                      class="input"
                      placeholder="商品总重量"
                    ></a-input-number>
                  </span>
                  <span v-if="visibles" style="color: red">
                    {{ visibleTexts }}
                  </span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="3" class="col">
            <a-button
              type="primary"
              icon="search"
              class="search-button"
              @click="onSearch"
            >
              搜索
            </a-button>
            <a-button icon="undo" class="btn-reset" @click="onReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="operation">
        <a-button class="btn-item" :disabled="disabled" @click="onSpellOrder">
          拼单
        </a-button>
      </div>
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :pagination="pagination"
        :loading="loading"
        :row-key="
          (record, index) => {
            return index;
          }
        "
        :row-selection="rowSelection"
        @change="onPage"
      >
        <template slot="orderSn" slot-scope="text, record">
          <div class="ordersn-container">
            <div>{{ record.orderSn }}</div>
            <div v-if="record.porderSn" class="order-forword">
              <img
                src="../../assets/order/icon-forword.png"
                alt="远期订单"
                class="icon-forword"
              />
              {{ record.porderSn }}
            </div>
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="onDetail(record.orderSn)">查看详情</a>
        </template>
      </a-table>
    </div>
  </a-layout-content>
</template>

<script>
import { fetchSpellList, patchOrder } from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    scopedSlots: { customRender: "orderSn" }
  },
  {
    title: "下单时间",
    dataIndex: "createTime"
  },
  {
    title: "订单状态",
    dataIndex: "orderStatus"
  },
  {
    title: "收货人地区",
    dataIndex: "consigneeAddress"
  },
  {
    title: "装运总体积（m³）",
    dataIndex: "totalVolume"
  },
  {
    title: "商品总重量（kg）",
    dataIndex: "totalWeight"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      visible: false,
      visibleText: "",
      visibles: false,
      visibleTexts: "",
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      selectedRowKeys: [],
      statusList: [
        { value: "待支付", label: "待支付" },
        { value: "已支付", label: "已支付" },
        { value: "全部", label: "全部" }
      ],
      startTime: "",
      endTime: "",
      disabled: false
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          let obj = {};
          if (selectedRows.length > 0) {
            obj = selectedRows.pop();
            if (obj.orderStatus !== "已支付") {
              this.$message.info("请选择已支付的订单进行拼单");
              return;
            }
            this.selectedRowKeys = selectedRowKeys;
          } else {
            this.selectedRowKeys = selectedRowKeys;
          }
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.orderStatus !== "已支付"
          }
        })
      };
    }
  },
  mounted() {
    this.onFetchList();
  },
  methods: {
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    onSearch() {
      this.pagination.current = 1;
      this.onFetchList();
    },
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.onFetchList();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      let obj = {};
      if (selectedRows.length > 0) {
        obj = selectedRows.pop();
        if (obj.orderStatus !== "已支付") {
          this.$message.info("请选择已支付的订单进行拼单");
          return;
        }
        this.selectedRowKeys = selectedRowKeys;
      } else {
        this.selectedRowKeys = selectedRowKeys;
      }
    },
    onFetchList() {
      this.loading = true;
      const params = {
        factoryId: localStorage.getItem("factoryId"),
        consigneeAddress: this.form.getFieldsValue().consigneeAddress,
        endTime: this.endTime,
        orderSn: this.form.getFieldsValue().orderSn,
        orderStatus: this.form.getFieldsValue().orderStatus,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        startTime: this.startTime,
        totalVolumeMax: this.form.getFieldsValue().totalVolumeMax,
        totalVolumeMin: this.form.getFieldsValue().totalVolumeMin,
        totalWeightMax: this.form.getFieldsValue().totalWeightMax,
        totalWeightMin: this.form.getFieldsValue().totalWeightMin
      };
      fetchSpellList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.loading = false;
          const data = resp.data.data;
          const records = data.records;
          if (records.length > 0) {
            records.forEach(item => {
              item.createTime = tsFormat(item.createTime);
            });
          }
          this.tblData = records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    handleOk() {
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    compareToFirstVolume(rule, value, callback) {
      const form = this.form;
      if (
        value &&
        form.getFieldValue("totalVolumeMin") &&
        value < form.getFieldValue("totalVolumeMin")
      ) {
        this.visible = true;
        this.visibleText = "请比前一个商品装运总体积大";
      } else {
        this.visible = false;
        callback();
      }
    },
    compareToSecondVolume(rule, value, callback) {
      const form = this.form;
      if (
        value &&
        form.getFieldValue("totalVolumeMax") &&
        value > form.getFieldValue("totalVolumeMax")
      ) {
        this.visible = true;
        this.visibleText = "请比前一个商品装运总体积小";
      } else {
        this.visible = false;
        callback();
      }
    },
    compareToFirstWeight(rule, value, callback) {
      const form = this.form;
      if (
        value &&
        form.getFieldValue("totalWeightMin") &&
        value < form.getFieldValue("totalWeightMin")
      ) {
        this.visibles = true;
        this.visibleTexts = "请比前一个商品装运总重量大";
      } else {
        this.visibles = false;
        callback();
      }
    },
    compareToSecondWeight(rule, value, callback) {
      const form = this.form;
      if (
        value &&
        form.getFieldValue("totalWeightMax") &&
        value > form.getFieldValue("totalWeightMax")
      ) {
        this.visibles = true;
        this.visibleTexts = "请比前一个商品装运总重量小";
      } else {
        this.visibles = false;
        callback();
      }
    },
    onSpellOrder() {
      this.disabled = true;
      if (this.selectedRowKeys.length < 2) {
        this.disabled = false;
        this.$message.info("请至少选择两个订单才能拼单");
      } else {
        const params = {
          factoryId: localStorage.getItem("factoryId"),
          orderSns: this.selectedRowKeys
        };
        patchOrder(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("拼单成功");
            this.selectedRowKeys = [];
            this.pagination.current = 1;
            this.onFetchList();
            this.disabled = false;
          } else {
            this.disabled = false;
          }
        });
      }
    },
    onDetail(orderSn) {
      this.$router.push({
        name: "UndeliveredDetail",
        params: { procureOrderSn: orderSn }
      });
    },
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.onFetchList();
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}
.row {
  display: flex;
}
.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.operation {
  display: flex;
  margin-bottom: 20px;
}
.btn-item {
  margin-right: 15px;
}
.span-input {
  line-height: 1.5;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  margin: 0;
  display: flex;
}
input {
  outline: none;
}
.span-input:focus {
  border-color: #618eff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(55, 105, 255);
}
.input {
  width: 44%;
  height: 99%;
  background-color: transparent;
  border: 0;
  outline: none;
}
.separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
.span-input /deep/ .ant-input-number-focused {
  box-shadow: none !important;
  border: none;
}
/deep/ .ant-input-number {
  margin: -4px 0px 0px 0px;
}
/deep/.ant-input-number-handler-wrap {
  display: none;
}

.btn-reset {
  margin-bottom: 10px;
}
</style>
