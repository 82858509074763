var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('Breadcrumb',[_c('BreadcrumbItem',[_vm._v("拼单列表")])],1)],1),_c('div',{staticClass:"content-container"},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form}},[_c('a-row',{staticClass:"row"},[_c('a-col',{attrs:{"span":21}},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"订单编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderSn']),expression:"['orderSn']"}],attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"下单时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['time']),expression:"['time']"}],on:{"change":_vm.onChangeTime}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"支付状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderStatus', { initialValue: '全部' }]),expression:"['orderStatus', { initialValue: '全部' }]"}],attrs:{"options":_vm.statusList}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"收货人地区"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['consigneeAddress']),expression:"['consigneeAddress']"}],attrs:{"allow-clear":""}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0px","height":"64px"},attrs:{"label":"商品装运总体积"}},[_c('span',{staticClass:"span-input"},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'totalVolumeMin',
                      { rules: [{ validator: _vm.compareToSecondVolume }] }
                    ]),expression:"[\n                      'totalVolumeMin',\n                      { rules: [{ validator: compareToSecondVolume }] }\n                    ]"}],staticClass:"input",attrs:{"min":0,"placeholder":"商品总体积"}}),_c('span',{staticClass:"separator"},[_vm._v("~")]),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'totalVolumeMax',
                      { rules: [{ validator: _vm.compareToFirstVolume }] }
                    ]),expression:"[\n                      'totalVolumeMax',\n                      { rules: [{ validator: compareToFirstVolume }] }\n                    ]"}],staticClass:"input",attrs:{"min":0,"placeholder":"商品总体积"}})],1),(_vm.visible)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.visibleText)+" ")]):_vm._e()])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0px","height":"64px"},attrs:{"label":"商品总重量"}},[_c('span',{staticClass:"span-input"},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'totalWeightMin',
                      { rules: [{ validator: _vm.compareToSecondWeight }] }
                    ]),expression:"[\n                      'totalWeightMin',\n                      { rules: [{ validator: compareToSecondWeight }] }\n                    ]"}],staticClass:"input",attrs:{"min":0,"placeholder":"商品总重量"}}),_c('span',{staticClass:"separator"},[_vm._v("~")]),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'totalWeightMax',
                      { rules: [{ validator: _vm.compareToFirstWeight }] }
                    ]),expression:"[\n                      'totalWeightMax',\n                      { rules: [{ validator: compareToFirstWeight }] }\n                    ]"}],staticClass:"input",attrs:{"min":0,"placeholder":"商品总重量"}})],1),(_vm.visibles)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.visibleTexts)+" ")]):_vm._e()])],1)],1)],1),_c('a-col',{staticClass:"col",attrs:{"span":3}},[_c('a-button',{staticClass:"search-button",attrs:{"type":"primary","icon":"search"},on:{"click":_vm.onSearch}},[_vm._v(" 搜索 ")]),_c('a-button',{staticClass:"btn-reset",attrs:{"icon":"undo"},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',{staticClass:"operation"},[_c('a-button',{staticClass:"btn-item",attrs:{"disabled":_vm.disabled},on:{"click":_vm.onSpellOrder}},[_vm._v(" 拼单 ")])],1),_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":_vm.pagination,"loading":_vm.loading,"row-key":function (record, index) {
          return index;
        },"row-selection":_vm.rowSelection},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"orderSn",fn:function(text, record){return [_c('div',{staticClass:"ordersn-container"},[_c('div',[_vm._v(_vm._s(record.orderSn))]),(record.porderSn)?_c('div',{staticClass:"order-forword"},[_c('img',{staticClass:"icon-forword",attrs:{"src":require("../../assets/order/icon-forword.png"),"alt":"远期订单"}}),_vm._v(" "+_vm._s(record.porderSn)+" ")]):_vm._e()])]}},{key:"action",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onDetail(record.orderSn)}}},[_vm._v("查看详情")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }